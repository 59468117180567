import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import get from 'lodash/get'
import parse from 'html-react-parser'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class GalleryList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: get(this.props.data, 'allContentfulGallery.nodes'),
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { location } = this.props;
    const { list,photoIndex,isOpen } = this.state;

    const siteMetadata = {
      title: 'Gallery | Limitless With Jess',
      description: 'Gatsby starter for bootstrap a blog',
      siteUrl: 'https://limitlesswithjess.com/gallery-list',
    }
    return (
      <Layout location={location}>
        <Meta site={siteMetadata} />
        <div className="content-wrapper">
          <div className="page-title gallery-list">
            <div className="container">
              <h1 className="entry-title heading-effect">
                <span className="title">Gallery</span>
              </h1>
              <nav
                className="breadcrumbs"
                itemScope
                itemType="http://schema.org/BreadcrumbList"
              >
                <meta itemProp="numberOfItems" content="2" />
                <meta itemProp="itemListOrder" content="Ascending" />
                <ol className="breadcrumb">
                  <li
                    itemProp="itemListElement"
                    itemScope
                    itemType="http://schema.org/ListItem"
                    className="breadcrumb-item"
                  >
                    <Link to="/">
                      <span itemProp="name">Home</span>
                    </Link>
                    <meta itemProp="position" content="1" />
                  </li>
                  <li
                    itemProp="itemListElement"
                    itemScope
                    itemType="http://schema.org/ListItem"
                    className="breadcrumb-item active"
                  >
                    <span itemProp="name">Latest Images</span>
                    <meta itemProp="position" content="2" />
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container main-wrapper clearfix">
            <div className="widget-list">
              <main
                className="row main-content clearfix gallery-content"
                itemScope="itemScope"
                itemType="http://schema.org/Blog"
              >
                {list && list.length ? (
                  list.map((item, index) => {
                    return (
                      <div className="col-md-4" key={index}>
                        <article
                          className="post has-post-thumbnail text-center"
                          itemScope="itemScope"
                          itemType="http://schema.org/BlogPosting"
                          itemProp="gallery"
                          key={index}
                        >
                          <figure className="entry-thumbnail"  onClick={() =>
                              this.setState({ isOpen: true, photoIndex: index })
                            }>
                            <img
                              src={item.image ? item.image.file.url : ''}
                              alt={
                                item.image.description
                                  ? item.image.description
                                  : ''
                              }
                              itemProp="image"
                            />
                          </figure>
                          <header className="pt-3">
                            <h2 className="entry-title" itemProp="headline">
                              {item.title ? item.title : ''}
                            </h2>
                            <div className="entry-meta text-center">
                              <span className="entry-time meta-date">
                                <i className="feather-calendar"></i>{' '}
                                <time
                                  itemProp="datePublished"
                                  dateTime="2018-09-22T05:50:47+00:00"
                                >
                                  {item.updatedAt}{' '}
                                </time>
                              </span>
                            </div>
                          </header>

                          <div className="entry-content mb-0" itemProp="description">
                            {item.description
                              ? parse(item.description.childMarkdownRemark.html)
                              : ''}
                          </div>
                        </article>
                      </div>

                    )
                  })
                ) : (
                    <article>
                      <div className="entry-content" itemProp="description">
                        <p>No images found.</p>
                      </div>
                    </article>
                  )}
              </main>
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={list[photoIndex].image.file.url}
                nextSrc={
                  list[(photoIndex + 1) % list.length].image.file.url
                }
                prevSrc={
                  list[(photoIndex + list.length - 1) % list.length]
                    .image.file.url
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + list.length - 1) % list.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % list.length,
                  })
                }
              />
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default GalleryList

export const query = graphql`
  {
    allContentfulGallery (sort: { fields: createdAt, order: DESC }) {
      nodes {
        description {
          childMarkdownRemark {
            html
          }
        }
        title
        updatedAt(formatString: "MMM Do YYYY")
        image {
          file {
            url
          }
          description
        }
      }
    }
  }
`
